import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import headerStyles from "../styles/header.module.scss"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.title}>
        <Link to="/">{data.site.siteMetadata.title}</Link>
      </div>

      <nav className={headerStyles.nav}>
        <a href="/resume.pdf" className={headerStyles.link}>
          Resume
        </a>
        <a href="https://mattfeng.tech/blog" className={headerStyles.link}>
          Blog
        </a>
      </nav>
    </header>
  )
}

export default Header
