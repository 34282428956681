import React, { Component } from "react"
import Layout from "../components/layout"

import styles from "../styles/index.module.scss"

import "../styles/globals.scss"
import "../styles/index.scss"

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <div className={styles.mainContainer}>
          <h2>About me</h2>
          <p>
            Hey there! I'm Matthew Feng, a master's student studying computer
            science and biology at the Masschusetts Institute of Technology.
          </p>
          <h2>Projects</h2>
          <table className="projects-table">
            <tr>
              <th className={styles.left}>Name</th>
              <th className={styles.center}>Status</th>
              <th className={styles.left}>Details</th>
            </tr>

            <tr>
              <td>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/mattfeng/redirect2"
                >
                  redirect2
                </a>
              </td>
              <td className={styles.center}>
                <span role="img" aria-label="crown">
                  👑
                </span>
              </td>
              <td>Build personal "tinyurls" to pages important to you.</td>
            </tr>

            <tr>
              <td colspan="3">
                key:{" "}
                <span role="img" aria-label="crown">
                  👑
                </span>{" "}
                = complete,{" "}
                <span role="img" aria-label="construction">
                  🚧
                </span>{" "}
                = in progress
              </td>
            </tr>
          </table>

          <h2>Coursework</h2>
          <ul className={styles.coursework}>
            <p>
              How to Make (Almost) Anything,{" "}
              <a href="https://htgaa22-mattfeng.notion.site/Matthew-Feng-fa7eecdc0ba54e16972113fc6baa004d">
                How to Grow (Almost) Anything
              </a>
              , Design and Analysis of Algorithms, Organic Chemistry,
              Biochemistry
            </p>
          </ul>

          <h2>Notes</h2>

          <p>
            I've compiled{" "}
            <a href="https://mattfeng.tech/teaching">
              some teaching notes here.
            </a>
          </p>

          <h2>Contact me</h2>
          <p>
            The best way to get in touch with me is via email:{" "}
            <span className="contact">mattfeng [at] mit [dot] edu</span>.
            Alternatively, you can reach me on Twitter:{" "}
            <span className="contact">@coffeeandsoju</span>.
          </p>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
