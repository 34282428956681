import React from "react"
import Header from "./header"

import styles from  "../styles/layout.module.scss"

const Layout = ({ data, children }) => {
  return (
    <div className={"container"}>
      <Header />

      <div>
        {children}
      </div>
    </div>
  )
}

export default Layout